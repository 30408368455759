<script src="../../../../../dist/cfg/settings.js"></script>
<template>
  <div>
    <div v-permission="'user.create'">
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }"
          >
            <template v-slot:body>
              <b-button-toolbar
                  aria-label="Toolbar with button groups and input groups"
              >
                <b-button-group size="sm" class="mr-1">
                  <b-button :to="{'name':'setting-user-edit'}" class="mr-2 btn-primary">Nuovo utente</b-button>
                  <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                </b-button-group>
              </b-button-toolbar>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>

      </div>
    </div>
    <div v-permission="'user.view'">
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
          >
            <template v-slot:body>

              <v-card>
                <v-card-title>
                  Elenco
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Cerca"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :single-select="singleSelect"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    multi-sortNO
                    item-key="id"
                    show-selectNO
                    class="elevation-1"
                    :search="search"
                >
                  <template v-slot:item.created_at="{ item }">
                    <span>{{item.created_at | formatDate}}</span>
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    <span>{{item.updated_at | formatDate}}</span>
                  </template>
                  <!--                                    <template v-slot:top>
                                                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                      </template>-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon  v-permission="'user.edit'"
                             small
                             class="mr-2"
                             @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
<!--                    <div class="inlineblock"><click-confirm>-->
<!--                      <v-icon  v-permission="'user.delete'"-->
<!--                               small-->
<!--                               @click="deleteItem(item)"-->
<!--                      >-->
<!--                        mdi-delete-->
<!--                      </v-icon>-->
<!--                    </click-confirm></div>-->
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import KTCodePreview from "@/views/partials/content/CodePreview.vue";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UserService from "../../../../common/user.service";

export default {
  data() {
    return {
      loading:false,
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'firstname', value: 'firstname'},
        {text: 'lastname', value: 'lastname'},
        {text: 'email', value: 'email'},
        {text: 'Updated At', value: 'updated_at'},
        {text: 'Created At', value: 'created_at'},

      ],
      items: [

      ],
    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Utenti", route: "/setting/user/list"},
      {title: "Lista utenti", route: "list"}
    ]);

  },

  created () {
    this.initialize()
  },

  methods: {
    reloadList(){
      this.loading = true;
      ApiService.get('users')
          .then(({ data }) => {
            this.items = data.rows;
            this.loading = false;
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    initialize () {
      if(UserService.hasPermission(['user.edit','user.delete'])){
        this.headers.push({ text: 'Actions', value: 'actions', sortable: false });
      }
      this.reloadList();
    },


    editItem (item) {
      this.$router.push({ name: "setting-user-edit", 'params':{'id':item.id} });
    },
    //
    // deleteItem (item) {
    //   //const index = this.desserts.indexOf(item)
    //   ApiService.post('users/delete/'+item.id,{'id':item.id}).then(() => this.reloadList());
    // },


  },
};
</script>
